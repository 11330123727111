import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => createStyles({
  conmutador: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
    '& > p': {
      fontWeight: 'bold',
      '& span': {
        color: theme.palette.primary.main
      }
    }
  },
  info: {
    marginTop: theme.spacing(5)
  },
  extension: {
    '& > p': {
      fontWeight: 'bold',
      '& span': {
        color: theme.palette.primary.main
      }
    }
  },
  table: {
    marginTop: theme.spacing(1),
    '& tbody tr:nth-child(odd)': {
      backgroundColor: '#eee'
    }
  },
  nested: {
    paddingLeft: theme.spacing(5)
  },
  list: {
    marginTop: theme.spacing(2)
  },
  message: {
    marginTop: theme.spacing(4),
    display: 'grid',
    gridTemplateColumns: '1fr',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '160px 1fr',
      gap: theme.spacing(4)
    }
  },
  image: {
    '& img': {
     
     
      width: 185,
      height: 155
    }
  },
  text: {
    '& > p': {
      marginTop: theme.spacing(3),
      '&:nth-child(1)': {
        marginTop: 0,
        [theme.breakpoints.down('xs')]: {
          marginTop: theme.spacing(3)
        }
      }
    }
  },
  materialTable: {
    '& .MuiFormControl-root.MuiTextField-root': {
      width: '100%',
      padding: 0
    },
    '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiOutlinedInput-adornedStart.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd input': {
      padding: '12px 0',
      width: '100%'
    }
},
  card: {
    cursor: 'pointer',
    height: '100%',
    boxShadow: theme.overrides.MuiPaper.elevation1['boxShadow'],
    '&:hover': {
      boxShadow: theme.overrides.MuiPaper.elevation4['boxShadow']
    }
  }
}));