import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { containerProps, contentProps, escala_gris } from './constants';

export const useGlobalStyles = makeStyles((theme: Theme) => createStyles({
  container: containerProps, 
  content: contentProps,
  listContent: {
    marginTop: theme.spacing(4),
  },
  list: {
    padding: 0,
    overflow: 'hidden',
    border: `1px solid ${grey[400]}`,
    borderRadius: 4
  },
  listTitle: {
    color: '#fff',
    backgroundColor: theme.palette.primary.main
  },
  listElement: {
    color: grey[700],
    '&:hover': {
      backgroundColor: grey[300],
      color: theme.palette.primary.main
    }
  },
  linkText: {
    cursor: 'pointer',
    color: escala_gris ? '#767576' : '9d2449',
   
    //textDecoration: 'underline',
    '&:hover': {
      color: '#c53863',
      //textDecoration: 'underline',
    },
  }
}));