import axios from "axios";
import { handleAxiosError } from "../functions"
import { ITramite } from "./interfaces";

export const getTramites = async () => {
  try {
    //const url = `https://tramites.tabasco.gob.mx/servicios/api/tramites-dependencias/?id=IS`;
    const url =  process.env.GATSBY_TRAMITE_API_URL;
    //`http://localhost:6004/api/citas/tramites_urls/`;
    const resp = await axios.get(url+'/tramites/tramites_urls/' || '', {
      headers: {
        //'Authorization': 'Token b6bf5d1f5c50c22bc07735538f808a5749c31468',
        'api-key':process.env.GATSBY_TRAMITE_API_KEY,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    });
    return resp.data as ITramite[]
  } catch (error) {
    handleAxiosError(error);
  }
}