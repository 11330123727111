import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

export const useStyles = makeStyles((theme: Theme) => createStyles({
  header: {
    width: '100%'
  },
  line: {
    width: 60,
    height: 5,
    content: '',
    backgroundColor: theme.palette.primary.main
  }
}));

const Header = ({ title }) => {
  const classes = useStyles();

  return (
    <header className={classes.header}>
      <Typography variant="h1">{title}</Typography>
      <div className={classes.line}></div>
    </header>
  )
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
}

Header.defaultProps = {
  title: ``,
}

export default Header
