import React, { useEffect, useState } from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Header from '../../components/header';
import { CircularProgress, Paper, Typography, useTheme } from "@material-ui/core";
import MaterialTable from 'material-table';
import { useGlobalStyles } from '../../utils/styles';
import { getTramites } from '../../utils/servicios-medicos/services';
import { ITramite } from '../../utils/servicios-medicos/interfaces';
import { removeAccents } from '../../utils/functions';
import { useStyles } from '../../utils/tu-instituto/styles';
import { toast } from 'react-toastify';

const Servicios = () => {
  const theme = useTheme();
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const [loading, setloading] = useState(false);
  const [tramites, setTramites] = useState<ITramite[]>([]);

  useEffect(() => {
    const getData = async () => {
      try {
        setloading(true);
        setTramites(await getTramites());
      } catch (error) {
        console.error(error);
        toast.error('Servicio no disponible por el momento.');
      } finally {
        setloading(false);
      }
    }

    getData()
  }, [])

  return (
    <Layout>
      <SEO title='Servicios' />

      <div className={globalClasses.container}>
        <Header title='Servicios y trámites al derechohabiente' />

        <div className={globalClasses.content}>

          <Paper elevation={1}>
            {loading ? (
              <div style={{ display: 'flex', justifyContent: 'center', padding: theme.spacing(2) }}>
                <CircularProgress size={40} color='primary' />
              </div>
            ) : null}

            {!tramites.length && !loading ? <Typography color='error' style={{ padding: theme.spacing(2), textAlign: 'center' }}>No se encontraron trámites o servicios disponibles</Typography> : null}

            {tramites.length && !loading ? (
              <div className={classes.materialTable}>
                <MaterialTable
                  title=''
                  columns={[
                    {
                      title: 'Nombre',
                      field: 'descripcion',
                      render: row => (
                        <>
                          <Typography variant='body2'>{row.descripcion}</Typography>
                          <Typography variant='caption' color='textSecondary'>{row.url}</Typography>
                        </>
                      ),
                      customFilterAndSearch: (filter: string, data) => removeAccents(data.descripcion.toLowerCase()).includes(removeAccents(filter.toLowerCase()))
                    }
                  ]}
                  data={tramites}
                  localization={{
                    pagination: {
                      labelDisplayedRows: '{from}-{to} de {count}',
                      labelRowsPerPage: 'registros',
                      nextTooltip: 'Siguiente',
                      previousTooltip: 'Atrás',
                      firstTooltip: 'Inicio',
                      lastTooltip: 'Último',
                      labelRowsSelect: 'registros',
                    },
                    toolbar: {
                      nRowsSelected: '{0} registro(s) seleccionados',
                      searchPlaceholder: 'Buscar por nombre del servicio o trámite',
                      searchTooltip: 'Filtrar'
                    },
                    header: {
                      actions: 'Acciones',
                    },
                    body: {
                      emptyDataSourceMessage: 'No se encontraron servicios o trámites',
                      filterRow: {
                        filterPlaceHolder: 'Buscar',
                        filterTooltip: 'Filtrar'
                      }
                    }
                  }}
                  options={{
                    search: true,
                    searchAutoFocus: true,
                    showTitle: false,
                    headerStyle: { display: 'none' },
                    searchFieldVariant: 'outlined',
                    pageSize: 5,
                    pageSizeOptions: [5, 15, 30, 45]
                  }}
                  onSearchChange={(search) => {
                    return true;
                  }}
                  onRowClick={(e, data) => window.open(data.url)}
                />
              </div>
            ) : null}
          </Paper>
        </div>
      </div>
    </Layout>
  );
};

export default Servicios;